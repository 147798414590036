<template>
  <div class="layer_05">
    <div class="layer_top">
      <h2 class="layer_top_title">(구)배너 {{ id ? "수정" : "등록" }}</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="layer_01_box">
      <form>
        <table class="layer_01_table">
          <colgroup>
            <col style="width: 107px" />
            <col style="width: 1147px" />
          </colgroup>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">제목</td>
            <td class="pb_15">
              <input type="text" class="text_box_03" v-model="content.title" />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="big_box_title pb_15">내용</td>
            <td class="pb_15">
              <textarea
                class="editor"
                style="width: 100%; height: 200px"
                v-model="content.text"
              ></textarea>
              <!--
												[D]
												에디터 입니다.
											 --></td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">커버 이미지</td>
            <td class="pb_15 standard">
              <input
                type="text"
                class="text_box_02 mr_30"
                v-model="imgName"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="file_upload"
                ref="refSelectedImg"
                @change="previewFile"
              />
              <label for="file_upload" class="file_btn"> 찾아보기 </label>
              <div
                v-if="selectedImg || imgName"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ imgName }}</span>
                <div class="cancel_img" @click.prevent="removeFile">
                  <span></span>
                  <span></span>
                </div>
              </div>
            </td>
          </tr>
          <!-- <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">태그</td>
            <td class="pb_15 standard">
              <div class="input_area_02">
                <input
                  type="text"
                  class="input_box_02 pl_12"
                  v-model="content.tag"
                  placeholder=", 로 구분"
                />
              </div>
            </td>
          </tr> -->
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">링크</td>
            <div class="input_area_02">
              <input
                type="text"
                class="input_box_02 pl_12"
                v-model="content.url"
              />
            </div>
          </tr>
        </table>
      </form>
      <div class="btn_center_wrapper">
        <base-button type="primary-lg" @click="edit">{{
          id ? "수정" : "등록"
        }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import Dialog from "@/components/Dialog.vue";
export default {
  components: {},
  props: {
    id: Number,
  },
  data() {
    return {
      validate: false,
      content: {
        title: "",
        text: "",
        // tag: "",
        url: "",
        file: [
          {
            url: "",
          },
        ],
      },
      imgName: "",
      selectedImg: null,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      if (this.id) {
        await client.oldBannerFindone(this.id).then((response) => {
          const { data } = response;
          console.log(data);
          this.content.title = data.content.title;
          this.content.text = data.content.text;
          // this.content.tag = data.content.tag;
          this.content.url = data.content.url;
          this.content.file.url = data.content.file[0].name;
          this.imgName = this.content.file.url;
        });
      }
    },
    async edit() {
      if (this.content.url) {
        if (!this.isValidUrl(this.content.url)) {
          alert("유효하지 않은 url입니다");
          this.validate = false;
        } else {
          this.validate = true;
        }
      } else {
        this.validate = true;
      }
      if (this.selectedImg) {
        let form = new FormData();
        form.append("uploadFile", this.selectedImg);
        await client.fileAdd(form).then(
          (response) => {
            this.content.file.url = response.data.name;
          },
          (error) => {
            console.log(error);
          }
        );
      }
      let params = {
        content: {
          title: this.content.title,
          text: this.content.text,
          // tag: this.content.tag,
          url: this.content.url,
          file: [
            {
              url: this.content.file.url,
            },
          ],
        },
      };
      if (!this.content.title) {
        alert("제목을 입력해수제요");
      } else if (!this.content.file.url) {
        alert("이미지를 선택해주세요");
      } else {
        if (this.validate) {
          if (this.id) {
            await client.oldBannerModify(this.id, params).then((response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "정상적으로 수정되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            });
          } else {
            await client.oldBannerAdd(params).then(
              (response) => {
                console.log(response);
                this.$modal.show(
                  Dialog,
                  {
                    title: "알림",
                    content: "배너가 정상적으로 등록되었습니다.",
                  },
                  {
                    width: 400,
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
                this.$emit("close", { refresh: true });
              },
              (error) => {
                console.log(error);
              }
            );
          }
        }
      }
    },
    removeFile() {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              if (this.selectedImg) {
                this.selectedImg = null;
              }
              this.imgName = "";
            }
          },
        }
      );
    },
    previewFile(e) {
      let refSelectedImg = this.$refs.refSelectedImg;
      if (0 < refSelectedImg.files.length) {
        this.selectedImg = refSelectedImg.files[0];
        let fileExt = this.selectedImg.name.substring(
          this.selectedImg.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          ["jpeg", "jpg", "png", "gif", "bmp"].includes(fileExt) &&
          this.selectedImg.size <= 1048576
        ) {
          const file = e.target.files[0];
          this.imgName = file.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectedImg = null;
        }
      } else {
        this.selectedImg = null;
      }
    },

    // url 유효성 검사
    isValidUrl(url) {
      let regUrl =
        /(http|https):\/\/((\w+)[.])+(asia|biz|cc|cn|com|de|eu|in|info|jobs|jp|kr|mobi|mx|name|net|nz|org|travel|tv|tw|uk|us)(\/(\w*))*$/i;
      return regUrl.test(url);
    },
  },
};
</script>
